window.getMembers = function (page, route, id, typ = 0, limit = 12, withVorstand = 0) {
    $.ajax({
        type: "GET",
        url: route,
        data: {
            page: page,
            typ: typ,
            limit: limit,
            withVorstand: withVorstand
        },
        success: function (data) {
            $("#members_" + id).html(data.view);

            pages_list = '';
            Object.entries(data.pages).forEach(([key, value]) => {
                pages_list = pages_list +
                    '<li id="members_' + id + '_page_' + key + '" class="page-item"><a class="page-link"' +
                    'href="' + route + '?page=' + key + '" ' +
                    'data-target-id="' + id + '"' +
                    'data-target-typ="' + typ + '"' +
                    'data-target-limit="' + limit + '"' +
                    'data-target-withVorstand="' + withVorstand + '">' + key + '</a>' +
                    '</li>';
            });

            $("#members_" + id + "_pages").html(pages_list);
            $("#members_" + id + "_pages li").removeClass('active');
            $("#members_" + id + "_page_" + page).addClass('active');
        }
    });
}
